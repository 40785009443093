































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import ProductHeader from '@/components/content/ProductHeader.vue'
import { Resource, ResourceTag } from '@/store/modules/resource'
import { GvdPage } from '@/store/modules/gvd'
import { SlideDeckPage } from '@/store/modules/slideDeck'
import ResourcePreviewModal from './ResourcePreview.modal.vue'
import axios from 'axios'
import store from '@/store/store'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { EventBus } from '@/utils/event-bus'
import { saveAs } from 'file-saver'
import { lookup } from 'mime-types'

@Component({
  components: {
    ProductHeader,
    vSelect,
    ResourcePreviewModal
  }
})
export default class Resources extends Vue {
  // searchbar/filter settings
  filter: string = ''
  pFilter: string = ''
  groupingEnabled: boolean = process.env.ENABLE_GROUPED_RESOURCES === 'true' || process.env.ENABLE_GROUPED_RESOURCES === true

  classicView: boolean = !this.groupingEnabled
  searchType: string = 'and'
  // keyword multi select
  resTypes: string[] = []
  ddlMarkets: string[] = this.getMarkets()
  ddlRegions: string[] = this.getRegions()
  isInitialLoad: boolean = true
  isVisible: boolean = true
  showResults: boolean = true
  loaderActive: boolean = false
  radioOptions: any[] = [
    { text: 'Grouped View', value: false },
    { text: 'Classic View', value: true }
  ]

  // table settings
  tableFields: any[] = [
    {
      key: 'show_details',
      label: 'Actions'
    },
    {
      key: 'resourceTypeName',
      label: 'Category',
      class: 'sortable text-nowrap',
      sortable: true
    },
    {
      key: 'name',
      label: 'Name',
      sortable: true
    },
    {
      key: 'fileExt',
      label: 'File extension'
    },
    {
      key: 'tags',
      label: 'Tags'
    }
  ]

  gruppedFields: any[] = [
    {
      key: 'show_details',
      label: 'Actions'
    },
    {
      key: 'name',
      label: 'Name',
      sortable: true
    },
    {
      key: 'fileExt',
      label: 'File extension'
    },
    {
      key: 'tags',
      label: 'Tags'
    }
  ]

  selectedResource: Resource | null = null
  selectedResourceType: string | null = null
  selectedMarket: string | null = null
  selectedRegion: string | null = null
  marketSelectionEnabled: boolean = process.env.MULTI_MARKET_REFERENCES === 'true' || process.env.MULTI_MARKET_REFERENCES === true
  cssColClass: string = this.marketSelectionEnabled ? 'col-6' : 'col-12'
  allowPreview: boolean = process.env.ALLOWPREVIEW === 'true'

  get resources () {
    this.loaderActive = true
    const res = this.$store.getters.getAllProductResources.map((resource: any) => {
      resource.resourceTypeName = resource.resourceType ? resource.resourceType.name : 'N/A'
      return resource
    }).filter((resource: Resource) => {
      // what category (or unassigned) is it
      let _matchesMarketSelection = true
      let _matchesRegionSelection = true
      let _matchesKeywords = true
      const _matchesResourceType = !this.selectedResourceType ||
        (resource.resourceType !== null && resource.resourceTypeName === this.selectedResourceType) ||
        (this.selectedResourceType === 'none' && resource.resourceType === null)

      if (this.marketSelectionEnabled && this.selectedRegion) {
        _matchesRegionSelection = resource.markets.map(x => x.region.name).includes(this.selectedRegion)
      }

      if (this.marketSelectionEnabled && this.selectedMarket) {
        _matchesMarketSelection = resource.markets.map(x => x.name).includes(this.selectedMarket)
      }
      if (this.pFilter) {
        _matchesKeywords = this.tableFilter(resource, this.pFilter)

        if (_matchesResourceType && _matchesMarketSelection && _matchesRegionSelection && _matchesKeywords) {
          return resource
        }
        return null
      } else {
        if (_matchesResourceType && _matchesMarketSelection && _matchesRegionSelection) {
          return resource
        }
        return null
      }
    }).sort(
      function (a: Resource, b: Resource) {
        return a.resourceTypeName.localeCompare(b.resourceTypeName) || a.name.localeCompare(b.name)
      })

    if (this.isInitialLoad) {
      // set category filter on initial load
      const allResourcesOptions = res.map((x: { resourceTypeName: String }) => x.resourceTypeName).sort()
      const dist = allResourcesOptions.filter((n: string, i: number) => allResourcesOptions.indexOf(n) === i).sort()
      this.resTypes = Object.freeze(dist)
      console.log(this.resTypes)
      this.isInitialLoad = false
      this.loaderActive = false
      return []
    }
    this.showResults = true
    this.loaderActive = false
    return res
  }

  resetView () {
    this.showResults = false
    this.pFilter = ''
    this.resetFields()
    this.resetMarket()
    this.resetRegion()
  }

  filteredResources (resources: any[], type: string) {
    const res = resources.filter(r => r.resourceType && r.resourceType.name === type)
    return res
  }

  categoryCount (type: string) {
    return this.resources.filter((r: { resourceType: { name: string } }) => r.resourceType && r.resourceType.name === type).length
  }

  setTableVisible () {
    this.isVisible = true
    this.loaderActive = false
  }

  setSpinner () {
    this.loaderActive = true
  }

  rowMarkets (market: any) {
    return market.markets.map((x: { name: any }) => x.name).join(', ')
  }

  popoverTags (resource: Resource) {
    return resource.resourceTags.map((x: { name: any }) => x.name).join(', ')
  }

  getMarkets () {
    const data = this.$store.getters.getMarkets
    let resp = []
    if (this.selectedRegion) {
      resp = data.filter((x: { region: { name: string | null } }) => {
        return x.region.name === this.selectedRegion
      }).map((x: { name: string }) => x.name).sort()
    } else {
      resp = data.map((x: { name: string }) => x.name).sort()
    }
    return resp
  }

  getRegions () {
    const data = this.$store.getters.getMarkets
    const resp = data.map((x: { region: any }) => x.region.name).filter((v: any, i: any, a: string | any[]) => a.indexOf(v) === i)
    return resp.sort()
  }

  get allResourceTagCategories () {
    return this.$store.getters.getResourceTagCategories.sort((a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name))
  }

  get allResourceTags () {
    return this.$store.getters.getResourceTags.sort((a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name))
  }

  get resourceTagOptions () {
    return this.allResourceTags.map((rt: ResourceTag) => {
      return {
        value: rt.key,
        label: rt.name
      }
    })
  }

  viewOptionClass () {
    return this.classicView ? 'col-4' : 'col-6'
  }

  convertResTypeToId (type: string) {
    return type.replace(/\s/g, '')
  }

  getTooltipMessage (resource: Resource) {
    let msg = ''

    if (process.env.ALLOWPREVIEW && (resource.fileExt === 'png' || resource.fileExt === 'pdf' || resource.fileExt === 'jpg')) {
      msg = 'Preview file'
    } else {
      msg = 'Download file'
    }
    return msg
  }

  resetFields () {
    this.filter = ''
    this.pFilter = ''
    this.selectedResourceType = null
    this.isVisible = true
  }

  resetMarket () {
    this.filter = ''
    this.pFilter = ''
    this.selectedMarket = null
    this.selectedResourceType = null
    this.isVisible = true
  }

  resetRegion () {
    this.pFilter = ''
    this.filter = ''
    this.selectedResourceType = null
    this.selectedRegion = null
    this.isVisible = false
  }

  updateDropdowns () {
    this.loaderActive = true
    this.isInitialLoad = false
    const allResTypes = this.resources.map((x: { resourceTypeName: String }) => x.resourceTypeName).filter((e: string) => e !== 'N/A')
    this.resTypes = allResTypes.filter((n: string, i: number) => allResTypes.indexOf(n) === i).sort()
    console.log(this.resTypes)
    this.ddlMarkets = this.getMarkets()
    setTimeout(() => {
      this.loaderActive = false
    }, 1200)
  }

  setTableFilter () {
    this.loaderActive = true
    this.pFilter = this.filter
    this.showResults = true
  }

  tableFilter (res: Resource, searchTerm: string) {
    this.isVisible = true
    const terms = this.searchType === 'exact' ? [searchTerm.toLowerCase()] : searchTerm.toLowerCase().split(' ')
    const resourceTags = res.resourceTags.map((x: { name: any }) => x.name).join(' ')
    const searchText = res.name + ' ' + res.description + ' .' + res.fileExt + ' ' + resourceTags
    return this.multiTermSearchAnd(searchText, terms)
  }

  multiTermSearchAnd (text: any, searchWords: string[]) {
    const results = []

    for (let i = 0; i <= searchWords.length; i++) {
      if (text.toLowerCase().indexOf(searchWords[i]) !== -1) { results.push(searchWords[i]) }
    }

    if (this.searchType === 'and') {
      return searchWords.length === results.length
    } else {
      return results.length > 0
    }
  }

  async beforeRouteUpdate (to: any, from: any, next: Function) {
    await store.dispatch('loadProduct', { productKey: to.params.productKey, marketKey: store.getters.getSelectedMarket })

    // await this.$store.dispatch('loadResourcesForMarket', this.$store.getters.getSelectedMarket)

    if (process.env.MULTI_MARKET_REFERENCES === 'true' || process.env.MULTI_MARKET_REFERENCES === true) {
      await store.dispatch('loadMarkets', to.params.productKey)
      await store.dispatch('loadProductResourcesSearch', to.params.productKey)
    } else {
      await store.dispatch('loadProductResourcesForMarket', to.params.productKey)
    }

    await store.dispatch('loadResourceTypes')
    await store.dispatch('loadResourceTagCategories')
    await store.dispatch('loadResourceTags')
    next()
  }

  async beforeRouteEnter (to: any, from: any, next: Function) {
    await store.dispatch('loadProduct', { productKey: to.params.productKey, marketKey: store.getters.getSelectedMarket })
    // await store.dispatch('loadResourcesForMarket', store.getters.getSelectedMarket)
    if (process.env.MULTI_MARKET_REFERENCES === 'true' || process.env.MULTI_MARKET_REFERENCES === true) {
      await store.dispatch('loadMarkets', to.params.productKey)
      await store.dispatch('loadProductResourcesSearch', to.params.productKey)
    } else {
      await store.dispatch('loadProductResourcesForMarket', to.params.productKey)
    }
    await store.dispatch('loadResourceTypes')
    await store.dispatch('loadResourceTagCategories')
    await store.dispatch('loadResourceTags')
    next()
  }

  getResourceTagsForCategory (categoryKey: string) {
    return this.allResourceTags.filter((resourceTag: ResourceTag) => {
      return resourceTag.resourceTagCategory.key === categoryKey
    })
  }

  getGvdPageLink (gvdPage: GvdPage) {
    const routeData = this.$router.resolve({ name: 'gvd', params: { productKey: this.$route.params.productKey, gvdKey: gvdPage.gvdSection.gvd.key }, query: { page: gvdPage.key } })
    window.open(routeData.href, '_blank')
  }

  getSlideDeckPageLink (slideDeckPage: SlideDeckPage) {
    const routeData = this.$router.resolve({ name: 'summary-slides', params: { productKey: this.$route.params.productKey, slideDeckKey: slideDeckPage.slideDeckSection.slideDeck.key }, query: { page: slideDeckPage.key } })
    window.open(routeData.href, '_blank')
  }

  async downloadFile (resource: Resource) {
    if (this.allowPreview && (resource.fileExt === 'png' || resource.fileExt === 'pdf' || resource.fileExt === 'jpg')) {
      this.selectedResource = resource
      this.$bvModal.show('resource-preview-modal')
    } else {
      EventBus.$emit('show-loader')
      const resp = await axios.get(`resources/download/${resource.key}`, { withCredentials: true, responseType: 'blob' })
      if (!resp || resp.status !== 200) {
        EventBus.$emit('hide-loader')
        return
      }
      const _blob = new Blob([resp.data], { type: lookup(resource.filename as string) || undefined })
      saveAs(_blob, resource.filename)
      EventBus.$emit('hide-loader')
    }
  }
}
