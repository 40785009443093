





























import { Component, Vue, Prop } from 'vue-property-decorator'
import { Resource } from '@/store/modules/resource'
import axios from 'axios'
import { lookup } from 'mime-types'
import { saveAs } from 'file-saver'
import { EventBus } from '@/utils/event-bus'

@Component
export default class ResourcePreviewModal extends Vue {
  @Prop({ default: '', required: false })
  resource?: Resource;

  resourceMimeType: string = '';
  fileSrc: string = '';
  disableDownload = true;

  mounted () {
    this.$root.$on('bv::modal::hide', (bvEvent: any, modalId: any) => {
      this.fileSrc = ''
      this.resourceMimeType = ''
    })
  }

  async loadContent () {
    EventBus.$emit('show-loader')
    this.disableDownload = true
    await axios
      .get(`resources/download/${this.resource?.key}`, {
        withCredentials: true,
        responseType: 'blob'
      })
      .then(response => {
        if (response.data === undefined) {
          this.disableDownload = true
          this.$bvModal.hide('resource-preview-modal')
        } else {
          const blob = new Blob([response.data], {
            type: lookup(this.resource?.filename as string) || undefined
          })
          const objectUrl = URL.createObjectURL(blob)
          this.resourceMimeType = lookup(this.resource?.filename as string) || ''
          this.fileSrc = objectUrl
          this.disableDownload = false
        }
      }).catch(() => {
        this.disableDownload = true
        EventBus.$emit('hide-loader')
      }).finally(() => {
        EventBus.$emit('hide-loader')
      })
  }

  downloadFile () {
    saveAs(this.fileSrc, this.resource?.filename)
  }
}
